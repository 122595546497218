import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    url: String,
    valueField: { type: String, default: "value" },
    labelField: { type: String, default: "label" },
    submitOnChange: false,
  };

  connect() {
    let options = {
      maxOptions: 5,
      openOnFocus: false,
    };

    if (this.hasUrlValue) {
      options.valueField = this.valueFieldValue;
      options.labelField = this.labelFieldValue;
      options.searchField = this.labelFieldValue;
    }

    if (this.submitOnChangeValue) {
      options.onChange = this.submitOnChange.bind(this);
    }

    this.select = new TomSelect(this.element, options);
  }

  disconnect() {
    this.select.destroy();
  }

  submitOnChange(value) {
    if (value) {
      this.element.form.requestSubmit();
      this.select.clear(true); // resets silently
    }
  }
}
